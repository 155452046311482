import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/src/layouts/post-layout.js"
import CustomImage from '$components/CustomImage'
import Vimeo from '$components/Article/Vimeo'
import Carousel from '$components/Carousel'
import DataProvider from '$components/DataProvider'
import ContentWrapper from '$components/Layout/ContentWrapper'
import Gallery from '$components/Layout/Gallery'
import Link from '$components/Text'
import { graphql } from 'gatsby'
export const pageQuery = graphql`
    query GatsbyImageMood {
        ...ImagesMood
    }
`
// Cmment
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = DefaultLayout
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             Layout={this.layout} layoutProps={props}
             components={components}>




<MDXTag name="h1" components={components}>{`Introduction`}</MDXTag>
<MDXTag name="p" components={components}>{`Major depression is the most common mental health problem.
Unfortunately, there is no one fits all solution. The right treatment often
depends on the trigger of the illness and makes each case unique. Thus, doctors
try different therapies and medicine in various doses to determine what works
best for individual patients. Which therapy or medication is chosen, depends
on the patient's mental state and feedback over the treatment period.
In standard therapy practices, the patient notes her mood throughout the day,
measured on a scale from 1 to 10 (sad to happy). The mood diary then gets evaluated
during each session with the psychotherapist.`}</MDXTag>
<MDXTag name="h1" components={components}>{`Outcome`}</MDXTag>
<MDXTag name="p" components={components}>{`"Nauu" revolutionizes the process by giving the patient a more relatable way of noting her feelings and the
psychotherapist gets more valuable data about the patient's state of mind, and the
circumstances from which her moods derive.`}</MDXTag>
<Carousel wrapper>
    <CustomImage imgSrc="home" />
    <CustomImage imgSrc="current_mood" />
    <CustomImage imgSrc="current_mood_1" />
    <CustomImage imgSrc="current_mood_2" />
    <CustomImage imgSrc="current_mood_3" />
    <CustomImage imgSrc="current_mood_4" />
</Carousel>
<MDXTag name="p" components={components}>{`Throughout the design process, it was vital for me to keep the colors and overall look
of the app neutral. The patient should not feel uncomfortable using the application in
non-private surroundings.
To test the design, we created a `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://framer.cloud/fStdm/"}}>{`framer prototype`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="h1" components={components}>{`Design Process`}</MDXTag>
<MDXTag name="p" components={components}>{`In the first design sprint, we explored the problems patients face in communication with their doctors and vice versa.`}</MDXTag>
<MDXTag name="blockquote" components={components}>
<MDXTag name="p" components={components} parentName="blockquote">{`How might we improve the doctor-patient communication?`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`An expert interview at the beginning of the project greatly helped to steer us in the right direction and find problems that play a significant role in the field of depression therapy.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Empathize`}</MDXTag>
<MDXTag name="p" components={components}>{`We established a line of communication with both patient and therapist in order to get the right information to work with further. The result of the interviews was that patients need support out- side therapy and simultaneously, that we would not seek to make a product that would replace therapy.`}</MDXTag>
<Gallery>
    <CustomImage
        imgSrc="persona_maria"
        description="Maria, the persona created from our initial interview with a patient"
    />
    <CustomImage imgSrc="user_flow" description="The app user-flow" />
</Gallery>
<MDXTag name="p" components={components}>{`The first interview with Maria set the course for the entire project, as we learned that there was a order in which certain symptoms occur, for instance there was a point of crisis and there was the aftermath of said crisis. We based our thoughts and ideas around this structure and set constraints, such as the service should not annoy the user. In the interview with the Psychologist, after reporting of the interviews with the patients, he reminded us that one case of depression is not like the other and urged us to think less about treating individual cases and more about helping a larger number of people. Even though not all cases are alike, there are a few common denominators, such as overextension. Furthermore he emphasized the necessity of an emotional log or diary, to keep track of the patients emotions. This sort of diary is part of therapy already, yet still happens on paper on a scale from 1 for sad to 10 for happy. Motivation should happen by giving positive feedback and creating a sense of achievement.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Defining the Problem`}</MDXTag>
<MDXTag name="p" components={components}>{`Where in the process should our service be placed? From the Interviews it became clear, we want the service to happen starting from the first assessment between doctor and patient, where the doctor has the possibility to lead the patient into the service and define user specific information to the service if necessary. After placing the service in the process, we defined the following pain points. The design sprint made me realize how important it is to define the problem early on. Without a clear goal, I often found to get lost in the creative process.`}</MDXTag>
<MDXTag name="p" components={components}>{`The emotional log or diary is still created with paper on a scale from 1 to 10, based only on happiness and thus hard to validate for the doctor.`}</MDXTag>
<MDXTag name="blockquote" components={components}>
<MDXTag name="p" components={components} parentName="blockquote">{`How might we make the emotional logging more intuitive for the patient?`}</MDXTag>
</MDXTag>
<MDXTag name="h1" components={components}>{`Ideating a solution`}</MDXTag>
<MDXTag name="p" components={components}>{`After defining the Problem, we decided to implement the service as a mobile app. Its ubiquitous, mostly handy in the morning and afternoon, when the user emotional logging is requested and creates the possibility for the user to do emergency calls if necessary without carrying around a separate device.`}</MDXTag>
<MDXTag name="blockquote" components={components}>
<MDXTag name="p" components={components} parentName="blockquote">{`Throughout the process, it was essential to always take the users privacy and potentially vulnerable mental state into consideration.`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`We brainstormed the aspects that should be included in our service. With a dot voting, we then decided which ones should make it in the first iteration of the final product as “basics”. The “features” where the aspects not necessary for the initial release. Things that would not greatly enhance the service or showcase its role in the therapy.`}</MDXTag>
<MDXTag name="h2" components={components}>{`How might we make the emotional logging more intuitive for the patient`}</MDXTag>
<MDXTag name="p" components={components}>{`In the beginning, we thought to place a mood diary inside the app violated one of our first set constraints,
which read that the app should never seem to annoy the user actively. However, it was through several
interviews with a patient and a psychologist that we learned the importance of this essential function.`}</MDXTag>
<Gallery>
    <CustomImage
        height={400}
        width={400}
        imgSrc="doc"
        description="Introducing our service to a psychologist during the fist interview"
    />
    <CustomImage
        height={400}
        width={600}
        imgSrc="user_testing"
        description="User test with Maria"
    />
</Gallery>
<MDXTag name="p" components={components}>{`Since the treatment revolves around the emotional state of the patient, this pain point had a high priority
in our envisioned service after the interviews. We wanted to make the process of emotion-logging easy and fun for the patient,
possibly giving him feedback on how this might help his treatment.`}</MDXTag>
<MDXTag name="blockquote" components={components}>
<MDXTag name="p" components={components} parentName="blockquote">{`We wanted to make the process of emotion-logging easy and fun for the patient, possibly giving him feedback on how this might help his treatment.`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`The significant upsides were that the patient has
a routine thing to do, day in, day out.`}</MDXTag>
<MDXTag name="p" components={components}>{`We agreed that the method must be kept rather simple, the user shouldn‘t have to go to too much trouble to document how she is feeling. For there to be any insights in the emotional diary there needed to be a contextual component, something to tell, from where and when and under which circumstances the patient felt the emotion. That way the therapist would be able to identify a pattern from the diary and gain insights into the patients’ mental state beyond therapy. Next, we needed to distinguish the right measure of emotion. We started with a simple scale ranging from negative to positive; the interaction happened through a slider.`}</MDXTag>
<MDXTag name="p" components={components}>{`This we noticed was a rather dull experience, that completely lacked any form reflexion upon the feeling you were documenting. Reflexion is essential to verify the emotion. Is this what I am feeling? With internal reflexion, the user confirms, if not to anyone else, at least to himself that yes, I am feeling this emotion. Without that reflexion process, the user might not even recognize his feeling when looking at the stats only a few days later. This would cause an inconsistency throughout the data and result in distrust from the user. Therefore component A needed some form of feedback. However, defining emotion is very difficult and almost entirely subjective. So we thought it best to keep our feedback abstract.
The Result was Günther (the cartoony face/slider).`}</MDXTag>
<Gallery>
    <CustomImage imgSrc="gunther_one" height={500} description="Neutral mood" />
    <CustomImage imgSrc="gunther_two" height={500} description="Great mood" />
</Gallery>
<MDXTag name="p" components={components}>{`As we made first scribbles of the app architecture, we decided that our current emotional diary system was,
too one-dimensional, as the other main feature of the app relied heavily on the input given in this step.
After a significant research period, we decided to go with a two-dimensional layout loosely based on a mix of
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://en.wikipedia.org/wiki/Contrasting_and_categorization_of_emotions#Plutchik.27s_wheel_of_emotions"}}>{`Plutschik’s “Wheel of Emotions”`}</MDXTag>{`
and `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://journals.sagepub.com/doi/10.1177/0539018405058216"}}>{`Sherer’s research`}</MDXTag>{` on the topic.`}</MDXTag>
<Vimeo
    width={640 / 2}
    height={1145 / 2}
    src="https://player.vimeo.com/video/311227049?background=1"
/>
<MDXTag name="p" components={components}>{`The result was a wheel capable of representing 13 distinct emotions, in a spectrum from positive to negative and high to low energy.`}</MDXTag>
<MDXTag name="h1" components={components}>{`My Role`}</MDXTag>
<MDXTag name="p" components={components}>{`On this project I was responsible for research (advancements and treatments in medicine and psychology), interaction concepts, prototyping in framer.js, and the screen design.`}</MDXTag>
           </MDXTag>
  }
}

export const _frontmatter = {};

  